<template>
  <div class="page-container md-layout-column">
    <div>
      <div class="container-fluid size my-progress">
        <h2 class="mb-0 px-0 title-favorites">mis favoritos</h2>

        <div v-if="isLoading">
          <div class="row mt-3">
            <skeleton-card-video
              v-for="index in 3"
              :key="index"
              class="col-md-4 col-lg-4 col-sm-6 mb-3"
            />
          </div>
        </div>
        <div v-else>
          <div v-if="this.getUserFavoriteClasses.items.length">
            <p
              class="favorite-description text-center my-3 fw-bold user__favorite__title__text__black"
            >
              Mantenga su contenido guardado organizado.
            </p>

            <div
              v-if="this.getUserFavoriteClasses.items.length"
              class="row mb-4 mx-auto"
            >
              <div
                v-for="_class in this.getUserFavoriteClasses.items"
                :key="_class.id"
                class="col-md-4 col-lg-4 col-sm-6 mb-2"
              >
                <card-class :data="_class" class="card-class-favorite-h-w" />
              </div>
            </div>

            <div
              v-if="
                getUserFavoriteClasses.items &&
                getUserFavoriteClasses.items.length
              "
            >
              <div
                v-if="totalPages > 1"
                class="d-flex pt-4 pb-2 d-flex justify-content-end border-top-0"
              >
                <base-pagination
                  :value="page"
                  class="pagination-style"
                  :page-count="totalPages"
                  @input="paginationChange"
                ></base-pagination>
              </div>
            </div>
          </div>
          <div v-else>
            <h3 class="text-center fw-bold">
              No tienes clases gratuitas favoritas selecionadas
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SkeletonCardVideo from '../../Components/skeletons/SkeletonCardVideo';
import { USER_CLASS_FAVORITE } from '../../../store/actions/user';
import CardClass from '../Courses/components/CardClass.vue';

export default {
  name: 'UserFavorites',
  components: {
    SkeletonCardVideo,
    CardClass,
  },

  data() {
    return {
      isLoading: true,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getUserFavoriteClasses']),
    totalPages() {
      return Math.ceil(this.getUserFavoriteClasses.total / this.perPage);
    },
  },
  mounted() {
    this.fetchClasses();
  },
  methods: {
    fetchClasses() {
      this.$store
        .dispatch(USER_CLASS_FAVORITE, {
          is_favorite: 1,
          page: this.page,
          per_page: 12,
        })
        .then(() => (this.isLoading = false));
    },
    paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      this.$store
        .dispatch(USER_CLASS_FAVORITE, { is_favorite: 1, page, per_page: 12 })
        .then(() => (this.isLoading = false));
      this.isLoading = false;
    },
  },
};
</script>
<style>
.favorite-description {
  letter-spacing: 0.6px;
}

.user__favorite__title__text__black {
  color: #000;
}

.card-class-favorite-h-w {
  width: 100% !important;
  height: 300px;
}
</style>
